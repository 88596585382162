<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field v-bind="$attrs" v-model="formatDate" :label="$t('global.month')" readonly v-on="on" />
    </template>
    <v-date-picker
      v-bind="$attrs"
      v-on="$listeners"
      v-model="selected"
      type="month"
      color="primary"      
      @input="menu = false"
    />
  </v-menu>
</template>

<script>
  export default {
    inheritAttrs: false,
    name: "month-picker",
    props: {
      value: [String, Number]
    },
    data: () => ({
      selected: null,
      menu: false,
      items: []
    }),
    watch: {
      value: {
        handler(v) {
          this.selected = v;
        },
        immediate: true
      }
    },
    methods: {
      generataMonths() {
        const months = this.$moment.monthsShort();
        this.items = months.map((item, index) => {
          return {
            text: this.$moment()
              .month(index)
              .format("MMMM"),
            value: index
          };
        });
      }
    },

    computed: {
      formatDate() {
        return this.$moment(this.selected).format("YYYY MMMM");
      }
    }
  };
</script>

<style scoped></style>
